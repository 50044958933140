import { Component, OnInit, ViewChild, ViewChildren, Output, EventEmitter, Input, SimpleChanges } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort } from "@angular/material";
import { ProjectsService } from "../../api/projects.service";
import { SetttingsService } from "../../api/setttings.service";
import { Router } from "@angular/router";
import { ProjectsTaskDialogComponent } from ".././projects-task-dialog/projects-task-dialog.component";
import { ShareProject } from '../../services/sharing/share-project.service';

@Component({
	selector: "app-projects",
	templateUrl: "./projects-page.component.html",
	styleUrls: ["./projects-page.component.scss"],
})
export class ProjectsPageComponent implements OnInit {
    userProjects: object[]
    columnsToDisplay = ['projectName', 'userHours', 'budgetedHours', 'totalHoursNonBilled', 'totalHoursBilled', 'hoursRemaining', 'tasks'];
    dataSourceLoading: boolean;
	userProjectsDataSource = new MatTableDataSource();
	projectUserMapping: any;

	@Input() searchFilterText = '';
    @Output() projectSelected = new EventEmitter<any>();

    @ViewChild(MatPaginator) projectsPaginator: MatPaginator;
    @ViewChildren(MatSort) sort;
	clients: any;
	taskStatuses: Object[];
	taskTypes: Object[];
    projects = [];
    priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
	{name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]

    constructor(private _projectsService: ProjectsService, public dialog: MatDialog,
        public snackBar: MatSnackBar, public router: Router, private shareProject: ShareProject, private _settingsService: SetttingsService) {
    }

    ngOnInit() {
        this.getUserProjects();
        this.getTaskTypesAndStatuses();
    }

	ngOnChanges(changes: SimpleChanges): void {
		this.filterProjectsBySearchInput(this.searchFilterText);
	}

	filterProjectsBySearchInput(searchInput) {
		if (searchInput != undefined) {
			this.userProjectsDataSource.filterPredicate =
				this.changeFilterPredicateToFilterProjectName();

			this.userProjectsDataSource.filter = searchInput
				.trim()
				.toLowerCase();
		}
	}

	changeFilterPredicateToFilterProjectName() {
		return (project, filters: string) => {
			const searchInput = filters.trim().toLowerCase();
			const colProjectName = project.name;

			const boolProjectExists = colProjectName
				.trim()
				.toLowerCase()
				.includes(searchInput);
			return boolProjectExists;
		};
	}

	projectSelect(projectId) {
		this.projectSelected.emit(projectId);
	}

	getTaskTypesAndStatuses() {
		this._settingsService.getStatuses().subscribe((data: Object[]) => {
			this.taskStatuses = data;
		});
		this._settingsService.getTaskTypes().subscribe((data: Object[]) => {
			this.taskTypes = data;
		});
	}

    getUserProjects(){
        this.userProjectsDataSource = new MatTableDataSource([]);
        this.dataSourceLoading = true;
        this._projectsService.getProjects().subscribe(
            data => {
                if(data != null){
                    let tempdata = Object.keys(data).map(i => data[i]);
                    this.projects = tempdata;
                    this.userProjectsDataSource = new MatTableDataSource(tempdata);
                    this.userProjectsDataSource.paginator = this.projectsPaginator;
					this.dataSourceLoading = false;

                    this.userProjectsDataSource.sort = this.sort.first;
                    this.userProjectsDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case 'projectName': return item['name'];
							case 'clientName': return item['clientName'];
                            case 'userHours': return item['userHours'];
							case 'totalHoursNonBilled': return item['totalHoursNonBilled'];
							case 'totalHoursBilled' : return item['totalHoursBilled'];
                            case 'budgetedHours': return item['budgetedHours'];
                            case 'hoursRemaining': return (item['budgetedHours'] - (item['totalHoursBilled'] + item['totalHoursNonBilled']));
                            default: return item[property];
                        }
                    };
                }
                else{
                    this.columnsToDisplay = [];
                    this.userProjectsDataSource = new MatTableDataSource(); // User isn't involved in any projects
                    this.dataSourceLoading = false;
                }
            },
            err => console.error(err)
        );
    }

    loadDialog(projectId) {
        let names = []; //array for the names for task status, task type, and priority
        let sendData = { newData: true };
        sendData['newData'] = true;
        let entry = new Object();
        sendData['entry'] = entry;
        sendData['entry']['isActive'] = true;
        sendData['entry']['projectId'] = projectId;
        sendData['entry']['labels'] = [];
        sendData['entry']['milestones'] = [];

        const dialogRef = this.dialog.open(ProjectsTaskDialogComponent, {
            width: '800px',
            height: 'auto',
            data: sendData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined)
				if (result['submit']) {
                    var task = this.taskStatuses.find(t => t['statusId'] == result['data']['taskStatus']);
                    names.push("Task Status: " + task['statusName']);
          
                    if(result['data']['typeId'] != null){
                        var type = this.taskTypes.find(t => t['typeId'] == result['data']['typeId']);
                        names.push("Task Type: " + type['typeName']);
                    }
          
                    var priority = this.priorities.find(p => p.id == result['data']['priority']);
                    names.push("Task Priority: " + priority.name);
                    result['data'].names = names;

                    var project = this.projects.find(project => project["id"] == result['data']["projectId"]);
					result['data'].projectName = ("project: " + project['name']);

                    this.addProjectTask(result['data']);
                }
		});
    }

    addProjectTask(entry){
        this._projectsService.putProjectTask(entry).subscribe(
            data => {
                this.snackBar.open("Work Item Added Successfully", "", { duration: 2000 });
            },
            err => {
                this.snackBar.open("Error Adding Work Item", "", { duration: 2000 });
            }
        );
    }
}
