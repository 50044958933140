import { Component, OnInit, ViewChild } from "@angular/core";
import { SetttingsService } from "../../api/setttings.service";
import { ManagementHrTitlePositionsComponent } from "./management-hr-title-positions/management-hr-title-positions.component";
import {
	MatTableDataSource,
	MatPaginator,
	MatDialog,
	MatSnackBar,
	MatButtonToggle,
	MatButtonToggleGroup,
} from "../../../../node_modules/@angular/material";
import { ManagementHrInviteDialogComponent } from "./management-hr-invite-dialog/management-hr-invite-dialog.component";
import { ManagementHrEmployeeDialogComponent } from "./management-hr-employee-dialog/management-hr-employee-dialog.component";
import { ElementArrayFinder } from "../../../../node_modules/protractor";
import { ManagementHrGroupDialogComponent } from "./management-hr-group-dialog/management-hr-group-dialog.component";
import { makeAnimationEvent } from "@angular/animations/browser/src/render/shared";
import { Auth0ManagementApiService } from "src/app/services/auth0-management-api/auth0-management-api.service";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
	selector: "app-management-hr",
	templateUrl: "./management-hr.component.html",
	styleUrls: ["./management-hr.component.scss"],
})
export class ManagementHrComponent implements OnInit {
	hrTitlePositions = ManagementHrTitlePositionsComponent;

	constructor(
		private _settingsService: SetttingsService,
		private auth0ManagementApiService: Auth0ManagementApiService,
		private authService: AuthService,
		public dialog: MatDialog,
		public snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getEmployeeData();
	}

	displayedColumns = ["name", "email", "role", "rate", "state", "options"];
	dataArray = [];
	inviteArray = [];
	dataSource = new MatTableDataSource();
	groupDataSource = new MatTableDataSource();
	dataSourceLoading: boolean = true;
	groups = [];
	positions = [];
	groupColumns = ["Name", "Options"];
	selectedGroup: Object;
	parents = [];
	currentToggle = null;
	roles = [];
	roleRelationships = [];
	rolesLoading = true;
	relationshipsLoading = true;
	searchValue = "";

	@ViewChild(MatPaginator) paginator;
	@ViewChild(MatButtonToggleGroup) buttonToggleGroup;

	updateFilter(toggleValue: string, searchValue: string) {
		let dataContainer = [];
		this.currentToggle = toggleValue;
		this.searchValue = searchValue;
		let booleanMaker = { active: true, inactive: false };
		if (toggleValue == "invites") {
			this.inviteArray.forEach((entry) => {
				if (entry["email"].toLowerCase().includes(searchValue.toLowerCase())) {
					dataContainer.push({
						name: entry["email"],
						email: "",
						role: "",
						rate: "",
						state: "",
						isInvite: true,
						id: entry["id"],
						tenantId: entry["tenantId"],
					});
				}
			});
		} else {
			this.dataArray.forEach((entry) => {
				let searchValues = [
					entry["fullName"].toLowerCase(),
					entry["email"].toLowerCase(),
					entry["positionInfo"]
						? entry["positionInfo"]["name"].toLowerCase()
						: "",
				];
				let searchBoolean = false;
				searchValues.forEach((value) => {
					if (value.includes(searchValue.toLowerCase()) || searchValue == "") {
						searchBoolean = true;
					}
				});
				let toggleBoolean =
					entry["isActive"] == booleanMaker[toggleValue] ||
					toggleValue == "all";
				if (searchBoolean && toggleBoolean) {
					dataContainer.push({
						name: entry["fullName"],
						userId: entry["userId"],
						email: entry["email"],
						role: entry["positionInfo"],
						rate: entry["defaultRate"],
						state: entry["isActive"],
						isAdmin: false,
					});
				}
			});
		}
		dataContainer = dataContainer.sort((a, b) => (a['name'] > b['name']) ? 1 : -1);
		this.dataSource = new MatTableDataSource(dataContainer);
		this.dataSource.paginator = this.paginator;
	}
	loadGroupDialog(groupId) {
		this.parents = [];
		for (let group of this.groups) {
			if (groupId == group["id"]) {
				this.selectedGroup = group;
			} else {
				this.parents.push(group);
			}
		}
		let data = Object();
		data["parents"] = this.parents;
		data["group"] = this.selectedGroup;
		if (data["group"] == null) {
			// let systemGroups = [new Object, new Object(), new Object(), new Object()];
			// for (let object of systemGroups) {
			//     object['level'] = 1;
			// }
			// data['group'] = new Object();
			// data['group']['systemGroupList'] = systemGroups;
		}
		const dialogRef = this.dialog.open(ManagementHrGroupDialogComponent, {
			width: "585px",
			height: "auto",
			data: data,
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.selectedGroup = null;
			if (result != null) {
				this._settingsService.postGroup(result).subscribe((data) => {
					this.getEmployeeData();
					this.getPositionAndGroupData();
					this.getGroupData();
				});
			}
		});
	}
	loadEditDialog(entryId) {
		let data = { newData: true };
		if (entryId != "newData") {
			data = this.findEntry(entryId);
			data["assignedRoles"] = this.roleRelationships.filter(relationship => relationship.userId == data['userId']);
		}
		data["groups"] = this.groups;
		data["positions"] = this.positions;
		data["roles"] = this.roles;
		const dialogRef = this.dialog.open(
			ManagementHrEmployeeDialogComponent,
			{
				width: "585px",
				height: "auto",
				data: data,
			}
		);
		dialogRef.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				if (result["submit"]) {
					this.updateData(result["data"]);
				}
			}
		});
	}

	findEntry(entryId, invite?) {
		if (invite) {
			return this.inviteArray.find((entry) => entry["userId"] == entryId);
		} else {
			return this.dataArray.find((entry) => entry["userId"] == entryId);
		}
	}

	stateChange(entryId, state) {
		let returnObject = this.findEntry(entryId);
		returnObject["isActive"] = state;
		this.updateData(returnObject);
	}

	processData(data) {
		let dataContainer = [];
		data.forEach((entry) => {
			dataContainer.push({
				name: entry["fullName"],
				userId: entry["userId"],
				email: entry["email"],
				role: entry["positionInfo"],
				rate: entry["defaultRate"],
				state: entry["isActive"],
				isAdmin: false,
			});
		});
		dataContainer = dataContainer.sort((a, b) => (a['name'] > b['name']) ? 1 : -1);
		this.dataSource = new MatTableDataSource(dataContainer);
		this.dataSource.paginator = this.paginator;
		this.updateFilter(this.buttonToggleGroup.value, this.searchValue);
	}
	processGroupData(data) {
		let dataContainer = [];
		data.forEach((entry) => {
			dataContainer.push({
				name: entry["name"],
				id: entry["id"],
				tenantId: entry["tenantId"],
				parentId: entry["parentId"],
			});
			this.groupDataSource = new MatTableDataSource(dataContainer);
			this.groupDataSource.paginator = this.paginator;
		});
	}
	getEmployeeData() {
		this.rolesLoading = true;
		this.relationshipsLoading = true;
		this.dataSource.data = [];
		this.dataSourceLoading = true;
		this.dataArray = [];
		this.dataArray.push.apply(this.dataArray, []);
		this._settingsService.getUsers(-1).subscribe(
			(data) => {
				this.dataArray.push.apply(this.dataArray, data);
				this.processData(this.dataArray);
				this.dataSourceLoading = false;
			},
			(err) => {
				this.dataSource = new MatTableDataSource(this.dataArray);
				this.dataSource.paginator = this.paginator;
				this.snackBar.open(err.statusText, "", { duration: 2000 });
				this.processData(this.dataArray);
				this.dataSourceLoading = false;
			}
		);
		this._settingsService.getUserRoles().subscribe(
			(data: any[]) => {
				this.roles = data;
				this.rolesLoading = false;
			},
			(err) => {
			}
		);
		this._settingsService.getRoleUsers().subscribe(
			(data: any[]) => {
				this.roleRelationships = data;
				this.relationshipsLoading = false;
			},
			(userErr) => {
			}
		);
	}

	getPositionAndGroupData() {
		this._settingsService.getHrGroups().subscribe(
			(data) => {
				this.groups = [];
				this.groups.push.apply(this.groups, data);
			},
			(err) => console.error(err)
		);
		this._settingsService.getPositions().subscribe(
			(data) => {
				this.positions = [];
				this.positions.push.apply(this.positions, data);
			},
			(err) => console.error(err)
		);
	}

	updateData(user) {
		this.auth0ManagementApiService
			.updateUserDetails(user.userId, user)
			.subscribe(
				(data) => {
					this.dataArray = this.dataArray.filter(user => user.userId != data.userId);
					this.dataArray.push(data);
					this.processData(this.dataArray);

					this.snackBar.open(
						"Settings Updated Successfully",
						"",
						{ duration: 2000 }
					);
				},
				(updateUserDetailsError) => {
					this.getEmployeeData();
					this.snackBar.open("Error Updating Settings", "", {
						duration: 2000,
					});
				}
			);
	}

	getPositionFunction() {
		return this._settingsService.getPositions();
	}

	putPositionFunction() {
		return (position) => this._settingsService.putPositions(position);
	}
	getGroupData() {
		this._settingsService.getHrGroups().subscribe((data) => {
			this.processGroupData(data);
		});
	}
	//PROS-889: Add Employee
	addNewEmployee() {
		let data = { newData: true };
		data["roles"] = this.roles;
		const dialogRef = this.dialog.open(ManagementHrInviteDialogComponent, {
			width: "585px",
			height: "auto",
			data: data,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				if (result["submit"]) {
					this.addNewUser(result["data"]);
				}
			}
		});
	}
	//PROS-889 - Proposed fix
	addNewUser(newUser) {
		//Pulled tenant name from authService, no subscription here
		let tenantName = this.authService.getTenantName();

		//Create the new user on the back-end
		this.auth0ManagementApiService
			.createNewUser(tenantName, newUser, true)
			.subscribe(
				(data) => {
					this.dataArray.push(data);
					this.processData(this.dataArray);

					this.snackBar.open("New User Added Successfully", "", {
							duration: 2000,
						}
					)
				},
				(err) => {
					this.getEmployeeData();
					this.snackBar.open("Error adding User", "", {
						duration: 2000,
					});
				}
			);
	}
}
