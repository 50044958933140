import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewChildren, OnChanges, SimpleChanges, Pipe, PipeTransform } from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { ProjectsService } from '../../api/projects.service';
import { MatTableDataSource, MatSnackBar, MatPaginator, MatSelect, MatDialog, MatButtonToggleGroup, MatSort } from '../../../../node_modules/@angular/material';
import { ManagementProjectDialogComponent } from './management-project-dialog/management-project-dialog.component';
import { ProjectStatusDialogComponent } from './management-project-status-dialog/project-status-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
    selector: 'app-management-projects',
    templateUrl: './management-projects.component.html',
    styleUrls: ['./management-projects.component.scss']
})

export class ManagementProjectsComponent implements OnInit {
    filterComponents: object[] = [];
    filterData;
    displayedColumns = ['client', 'name', 'status', 'state', 'options'];
    projectStatusDisplayedColumns = ['name', 'order', 'options', 'delete'];
    dataArray = [];
    dataSource = new MatTableDataSource();
    dataSourceLoading: boolean;
    clientsLoading: boolean;
    projectDataSourceLoading: boolean;
    dataHolder = [];
    tempHolder: number;
    tempStatusHolder;
    managers;
    allUsers;
    clients;
    selectedClients: any = [];
    statuses;
    allFilters: object[] = [[], [], [], []]; //status, client, search, toggle
    projectStatusDataSource = new MatTableDataSource();
    searchFilterText: string = '';
	searchFilterInputBox: string;
    multiSort: Sort[] = [
        { id: 'name', direction: 'desc' },
        { id: 'client', direction: 'asc' }
      ];

      constructor(private _settingsService: SetttingsService,
        public _projectsService: ProjectsService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    @ViewChild('filterSelectSearch') filterSelectSearch;
    @ViewChild('filterSelectStatus') filterSelectStatus;
    @ViewChild('filterSelectClient') filterSelectClient;
    @ViewChildren(MatPaginator) paginator;
    @ViewChild(MatButtonToggleGroup) buttonToggleGroup;
    @ViewChild(MatSort) sort : MatSort;
 
    ngOnInit() {
        this.getClientData();
        this.projectStatusDataSource.data = [];
        this.dataSource.data = [];
        this.projectDataSourceLoading = true;
        this.dataSourceLoading = true;
        this.clientsLoading = true;
       
        this._settingsService.getUsers(true).subscribe(data => {
            this.managers = data;
            this.getProjectData();
        });
        this._settingsService.getUsers(-1).subscribe(data => {
            this.allUsers = data;
        });
       
        this.getProjectStatus();
        this.filterData = this.dataSource.data;
    }
    
    onKeyClient(value) {
		this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string) {
		let filter = value.toLowerCase();
		return this.clients.filter(option => option.name.toLowerCase().includes(filter));
	}

    getClientData(){
        this.clientsLoading = true;
        this._settingsService.getClients().subscribe(data => {
            this.clients = data;
            this.selectedClients = data;
            if(this.dataSourceLoading == false){
                this.processData(this.dataArray);
            }
            this.clientsLoading = false;
        }
        , err => {
            this.snackBar.open('Error Retrieving Clients', '', { duration: 2000 });
            console.error(err);
        });
    }

    getProjectStatus(){
        this._projectsService.getStatus().subscribe(data => {
            this.statuses = data;
            this.projectStatusDataSource = new MatTableDataSource(this.statuses);
            this.projectStatusDataSource.paginator = this.paginator.toArray()[1];
            this.projectDataSourceLoading = false;
        });
    }
   

    updateFilter(toggleValue: string, searchValue: string) {
        let dataContainer = [];    
        let booleanMaker = { active: true, inactive: false };
        this.dataArray.forEach(entry => {
            let searchBoolean = entry['name'].toLowerCase().includes(searchValue) || entry['client'].toLowerCase().includes(searchValue) || searchValue == '';
            let toggleBoolean = entry['isActive'] == booleanMaker[toggleValue] || toggleValue == 'all';
            if (searchBoolean && toggleBoolean) {
                dataContainer.push({
                    name: entry['name'],
                    client: entry['client'],
                    status: entry['status'],
                    statusId: entry['statusId'],
                    state: entry['isActive'],
                    id: entry['id']
                });
            }
        });
  
        this.dataSource = new MatTableDataSource(dataContainer);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataHolder = this.dataSource.data;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'client': return item['client'];
                case 'name': return item['name'].toLowerCase();
                case 'status': return item['status'];
                default:  return item[property];
            }
        };
        this.initSort(); //sets the defaul sort of client and name everytime 
        this.refreshFilter(toggleValue, 'Toggle'); //adds toggle switch to refreshed filter
    }

   

    onKeyFilter(value){
        this.dataSource.filter = value;
    }

    setFocus(inputId: string) {
        if (document.getElementById(inputId) != null) {
            document.getElementById(inputId).focus();
        }
    }
    clearSelectedClients(select?) {
        this.dataSource.data = this.dataHolder;
        this.dataSource.filter = '';
        select.value = null;
    }

    clearSelectedStatus(select?) {
        this.dataSource.data = this.dataHolder;
        this.dataSource.filter = '';
        select.value = null;
    }

    findEntry(entryId) {
        return this.dataArray.find(entry => entry['id'] == entryId);
    }

    findProjectStatusEntry(entryId){
        return this.statuses.find(entry => entry['statusId'] == entryId);
    }

    loadDialog(entryId) {
        let sendData = { newData: true };
        sendData['newData'] = true;
        sendData['managers'] = this.managers;
        sendData['allUsers'] = this.allUsers;
        sendData['clients'] = this.clients;
        let entry = new Object();
        sendData['entry'] = entry;
        sendData['entry']['isActive'] = true;
		let projects = this.dataSource.data;
		sendData['projects'] = projects;

        //if new project grab project users
        if (entryId != 'newData') {
            this.snackBar.open("Opening Edit", "", { duration: 2000 });
            sendData['entry'] = this.findEntry(entryId);
            sendData['newData'] = false;
            this._settingsService.getProjectUsers(entryId).subscribe(
                data => {
                    //set assigned users and grab manager rate to be set
                    sendData['entry'].assignedUsers = data;
                    for(let i = 0; i < sendData['entry'].assignedUsers.length; i++) {
                        if(sendData['entry'].assignedUsers[i].projectRole == 'Project Manager') {
                            sendData['entry'].projectManagerRate = sendData['entry'].assignedUsers[i].projectRate;
                        }
                    }
                    this.reusableProjectDialog(sendData);
                });
        //if add new projet
        } else {
            this.reusableProjectDialog(sendData);
        }
    }

    reusableProjectDialog(dialogData) {
        const dialogRef = this.dialog.open(ManagementProjectDialogComponent, {
            width: '800px',
            height: 'auto',
            data: dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.updateProjectData(result['data']);
                }
                if (result['openProjectFromRelationship']){
                    this.loadDialog(result['projectId'])
                }
            }
        });
    }

    loadProjectStatusDialog(entryId) {
        let sendData = { newData: true };
        sendData['newData'] = true;
        let entry = new Object();
        sendData['entry'] = entry;

        if (entryId != 'newData') {
            sendData['entry'] = this.findProjectStatusEntry(entryId);
            sendData['newData'] = false;
        }
        const dialogRef = this.dialog.open(ProjectStatusDialogComponent, {
            width: '800px',
            height: 'auto',
            data: sendData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.addUpdateProjectStatusData(result['data']);
                }
            }
        });
    }

    stateChange(entryId, state) {
        let returnObject = this.findEntry(entryId);
        returnObject['isActive'] = state;
        this.updateProjectData(returnObject);
    }

    checkClientAndProjectStatus(entryId){
        let returnObject = this.findEntry(entryId);
        if(returnObject['isActive'] == false){
            var client = this.clients.find(client => client.id == returnObject['clientId']);
            if(client.isActive == false){
                return true;
            }
        }
        return false;
    }

    processData(data) {
        let dataContainer = [];
        data.forEach(entry => {
                let clientFound = false;
                for(let i = 0; i < this.clients.length; i++){
                    if(this.clients[i].id === entry.clientId){
                        entry["client"] = this.clients[i].name;
                        if(!this.clients[i]['projects']) this.clients[i]['projects'] = [];
                        if(entry['isActive']) this.clients[i]['projects'].push(entry); // pushing active projects to clients
                        clientFound = true;
                    }
                }

                entry['status'] = "";
                if(entry['statusId'] != null){
                    entry['status'] = this.statuses.find(x => x['statusId'] ==
                        entry['statusId'])['statusName'];
                }
                dataContainer.push({
                    name: entry['name'],
                    client: entry['client'],
                    status: entry['status'],
                    statusId: entry['statusId'],
                    state: entry['isActive'],
                    id: entry['id']
                });

                this.dataSource = new MatTableDataSource(dataContainer);
                this.updateFilter(this.buttonToggleGroup.value, ''); 
        });
        this.initSort();
    }

    getProjectData() {
        this.dataSource.data = [];
        this.dataSourceLoading = true;
        this._settingsService.getProjectsNoUser(false).subscribe(
            data => {
                this.dataArray = [];
                this.dataArray.push.apply(this.dataArray, data);
                if(this.clientsLoading == false){
                    this.processData(data);
                }
                this.dataSource.paginator = this.paginator.toArray()[0];
                this.dataSourceLoading = false;
            }, err => {
                this.dataSource = new MatTableDataSource(this.dataArray);
                this.dataSource.paginator = this.paginator.toArray()[0];
                this.snackBar.open('Error Retrieving Projects', '', { duration: 2000 });
                console.error(err);
                this.dataSourceLoading = false;
            }
        );
     
    }

    updateProjectData(entry) {
        this._settingsService.putProject(entry).subscribe(
            data => {
                this.getProjectData();
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.getProjectData();
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    addUpdateProjectStatusData(entry){
        this._projectsService.putStatus(entry).subscribe(
            data => {
                this.getProjectStatus();
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.getProjectStatus();
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    deleteProjectStatus(entryId){
        const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
            width: '585px',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
            if (result !== undefined) {
                if (result['toDelete']) {
                    this.snackBar.open('Deleting Data', '', { duration: 2000 });
                    this._projectsService.deleteStatus(entryId).subscribe(
                        data => {
                            this.getProjectStatus();
                            this.snackBar.open('Deleted', '', { duration: 2000 });
                        }, err => {
                            this.getProjectStatus();
                            this.snackBar.open(err.statusText, '', { duration: 2000 });
                        }
                    );
                }
            }
        });
    }

    //new method that allows all filters on page to work together
    refreshFilter(filterItems, type, refreshForProject = false) {
        this.filterData = Array.from(this.dataHolder);

			var tempFilter = Object.assign({}, this.allFilters);

			if (type == 'Clients') {
				tempFilter[0] = filterItems;
			}
			else if (type == 'Statuses') {
				tempFilter[1] = filterItems;
			}
			else if (type == 'Search') {
				tempFilter[2] = filterItems;
			}
            else if (type == 'Toggle') {
				tempFilter[3] = filterItems;
			}
            
			this.allFilters = tempFilter;
    
            let clientFilter = Object.keys(this.allFilters[0]).map(i => this.allFilters[0][i]);
            let statusFilter = Object.keys(this.allFilters[1]).map(i => this.allFilters[1][i]);
            let searchFilter = this.allFilters[2].toString().toLowerCase();
            let toggleFilter = Object.keys(this.allFilters[3]).map(i => this.allFilters[3][i]);

            for (var i = 0; i < this.filterData.length; i++) {
                this.filterData = this.filterData.filter(function (data) {
                    return ((statusFilter.length == 0 || statusFilter.indexOf(data['statusId']) !== -1) &&
                    (clientFilter.length == 0 || clientFilter.indexOf(data['client']) !== -1) && 
                    (searchFilter == ""|| data["client"].toLowerCase().includes(searchFilter)
                    || data["name"].toLowerCase().includes(searchFilter))&&
                    (toggleFilter.length == 0 || toggleFilter.indexOf(data['state'] !== -1)));

                });
            }
          
           this.dataSource.data = this.filterData;
		}

    
    
    
       initSort() {
        if (!this.multiSort) {
          return;
        }
      
        let sortedData = [];
        this.multiSort.forEach(sort => {
          sortedData = this.dataSource.data
          .sort((a, b) => {
            if (sort.direction === 'asc') {
                    return a[sort.id] > b[sort.id] ? 1 : -1;
            } 
            else if (sort.direction === 'desc') {
                if(a['name']){
                    return a[sort.id].toLowerCase() > b[sort.id].toLowerCase() ? -1: 1;
                }
                else{
                    return a[sort.id] > b[sort.id] ? -1: 1;
                }
            } else {
              return 0;
            }
          });
        });
        this.dataSource.data = sortedData;
      }
    
      
}

export interface Sort {
    id: string;
    direction: 'asc' | 'desc';
  }