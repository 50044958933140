import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatDialog, MatSnackBar, MatButtonToggleGroup, MatButton, MatButtonToggle } from '@angular/material';
import { PortfolioService } from '../api/portfolio.service';
import { SetttingsService } from '../api/setttings.service';
import { DeleteConfirmationDialogComponent } from '../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ProjectPortfoliosDialogComponent } from './project-portfolios-dialog/project-portfolios-dialog.component';

@Component({
    selector: 'project-portfolio',
    templateUrl: './project-portfolios.component.html',
    styleUrls: ['./project-portfolios.component.scss']
})

export class ProjectPortfoliosComponent implements OnInit {
    @ViewChild(MatPaginator) paginator;
    @ViewChild(MatSelect) filterSelect;
    @ViewChild(MatSelect) altfilterSelect;
    @ViewChildren(MatSort) sort;

    portfolioStatuses = [
        {
            name: 'On Track',
            id: 1
        },
        {
            name: 'Slight Risk',
            id: 2
        },
        {
            name: 'High Risk',
            id: 3
        }
    ];

    displayedColumns = ['id', 'name', 'status', 'duration', 'completion', 'plannedHours', 'actualHours',
        'plannedStart', 'actualStart', 'plannedCompletion', 'actualCompletion', 'options', 'delete'];

    portfolioList = [];
    selectedProjects: any[];
    projects: any;
    dataSource = new MatTableDataSource();
    dataSourceLoading: boolean;
    clients;
    projectNames = [];
    dataHolder;
    currentEntry: Object = null;
    toggleRelations: boolean = false;

    constructor(public dialog: MatDialog, private _settingsService: SetttingsService,
        public snackBar: MatSnackBar, private _portfolioService: PortfolioService) { }

    ngOnInit() {
        this.getClients();
        this.getPortfolios();
    }

    getPortfolios() {
        this.dataSourceLoading = true;

        this._portfolioService.getAllPortfolios().subscribe(
            portfolioData => {
                this.dataSourceLoading = false;
                this.portfolioList = Object.assign([], portfolioData);
                this.dataSource = new MatTableDataSource(this.portfolioList);

                this.sortDataSource();
                this.refreshProjectPortfoliosFilter();
                this.dataSource.paginator = this.paginator;
            },
            portfolioErr => {
                this.dataSourceLoading = false;
                this.snackBar.open('Error Retrieving Portfolios', '', { duration: 2000 });
            }
        );

    }

    getClients() {
        this.clients = [];
        this.selectedProjects = [];

        this._settingsService.getProjectsandClients('active', 'active').subscribe((data: Object[]) => {
            this.clients = data;
            this.selectedProjects = data;
		});
    }

    //This is used to go into the details section of the project portfolios
    toggleSet(value: boolean) {
        this.toggleRelations = value;

        if (value == false) {
            this.dataSource = new MatTableDataSource([]);
            this.getPortfolios();
        }
    }

    sortDataSource() {
        this.dataSource.sort = this.sort.first;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'id': return item['id'];
                case 'name': return item['name'];
                case 'status': return item['status'];
                case 'duration': return item['duration'];
                case 'completion': return item['completion'];
                case 'plannedHours': return item['plannedHours'];
                case 'actualHours': return item['actualHours'];
                case 'plannedStart': return item['plannedStart'];
                case 'actualStart': return item['actualStart'];
                case 'plannedCompletion': return item['plannedCompletion'];
                case 'actualCompletion': return item['actualCompletion'];
                default: return item[property];
            }
        };
        if (this.dataSource.data !== null) {
            this.dataSource.data.forEach((item: any) => {
                this.projectNames.push(item['name']);
            });
        }
        this.dataHolder = this.dataSource.data;
    }

    refreshProjectPortfoliosFilter(searchValue?: string) {
        let filteredPortfoliosArray = [];
        this.portfolioList.forEach(entry => {
            if ((this.filterSelect == undefined || this.filterSelect.value == undefined ||
                this.filterSelect.value.includes(entry.status) || this.filterSelect.value.length == 0) &&
                (searchValue == undefined || `${entry['id']}`.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                    searchValue == undefined || entry['name'].toLowerCase().includes(searchValue.toLowerCase()) ||
                    searchValue == '')) {
                filteredPortfoliosArray.push(entry);
            }
        });

        if (this.filterSelect != undefined && this.filterSelect.value != undefined && this.filterSelect.value.length == 0
            && (searchValue == undefined || searchValue == '')) {
            this.dataSource = new MatTableDataSource(this.portfolioList);
            this.sortDataSource();
        }
        else if (this.filterSelect != undefined && this.filterSelect.value != undefined || searchValue != undefined) {
            this.dataSource = new MatTableDataSource(filteredPortfoliosArray);
            this.sortDataSource();
        }
        else {
            this.dataSource = new MatTableDataSource(this.portfolioList);
            this.sortDataSource();
        }
    }

    refreshFilter(clients: any): void {
        clients = JSON.stringify(clients);
        let selectedClients = clients.split(',');
        let filterArray = [];
        if (selectedClients.length !== 0) {
            selectedClients.forEach((item: any) => {
                this.dataSource.data = this.dataHolder;
                if (this.dataSource.data !== null) {
                    this.dataSource.data.forEach((item2: any) => {
                        if (item.includes(item2['name'])) {
                            filterArray.push(item2);
                        }
                        });
                    }
            });
            this.dataSource.data = filterArray;
        }
    }

    clearSelectedStatusFilters(select: MatSelect, searchValue?) {
        select.value = null;
        this.refreshProjectPortfoliosFilter(searchValue);
        this.dataSource.paginator = this.paginator;
    }

    loadDialog() {
        let sendData = { newData: true, entry: new Object() };

        const dialogRef = this.dialog.open(ProjectPortfoliosDialogComponent, {
            width: '800px',
            height: 'auto',
            data: sendData
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult !== undefined) {
                if (dialogResult['submit']) {
                    this.putPortfolioData(dialogResult['data']);
                }
            }
        });
    }

    loadDetails(portfolioId) {
        this.currentEntry = {
            entry: this.portfolioList.find(portfolio => portfolio.id == portfolioId),
            selectedProjects: this.selectedProjects,
            clients: this.clients
        };
        this.toggleRelations = true;
    }

    putPortfolioData(portfolio) {
        this.dataSource = new MatTableDataSource([]);
        this.dataSourceLoading = true;
        this._portfolioService.putPortfolio(portfolio).subscribe(
            portfolioData => {
                if(portfolioData != null && portfolioData[0] != null){
                    portfolio.id = portfolioData[0]['PP_ID'];
                    portfolio.duration = 0;
                    portfolio.completion = 0;
                    portfolio.plannedHours = 0;
                    portfolio.actualHours = 0;

                    this.portfolioList.push(portfolio);
                }
                this.dataSource = new MatTableDataSource(this.portfolioList);

                this.sortDataSource();
                this.refreshProjectPortfoliosFilter();
                this.dataSource.paginator = this.paginator;
                this.dataSourceLoading = false;

                this.snackBar.open('Successfully Added/Updated Portfolio', '', { duration: 2000 });
            },
            portfolioErr => {
                this.snackBar.open('Error Adding/Updated Portfolio', '', { duration: 2000 });
                this.getPortfolios();
            }
        );
    }

    deletePortfolio(portfolioId) {
        const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
            width: '585px',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(dialogResult => {// Check if the entry is to be deleted
            if (dialogResult !== undefined) {
                if (dialogResult['toDelete']) {
                    this.dataSource = new MatTableDataSource([]);
                    this.dataSourceLoading = true;
                    this._portfolioService.deletePortfolio(portfolioId).subscribe(
                        deletePortfolioData => {
                            this.portfolioList = this.portfolioList.filter(portfolio => portfolio.id != portfolioId);
                            this.dataSource = new MatTableDataSource(this.portfolioList);
                            this.dataSource.paginator = this.paginator;
                            this.dataSourceLoading = false;
                            this.snackBar.open('Successfully Deleted Portfolio', '', { duration: 2000 });
                        },
                        deletePortfolioErr => {
                            this.snackBar.open('Error Deleting Portfolio', '', { duration: 2000 });
                            this.getPortfolios();
                        }
                    );
                }
            }
        });
    }
}
