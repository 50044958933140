import { Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { SetttingsService } from '../api/setttings.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ProjectsService } from '../api/projects.service';
import { ShareProject } from '../services/sharing/share-project.service';
import { AuthService } from '../services/auth/auth.service';
import { SprintService } from '../api/sprint.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Auth0ManagementApiService } from '../services/auth0-management-api/auth0-management-api.service';
import { DeleteConfirmationDialogComponent } from '../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
var async = require('async');
var cloneDeep = require('lodash.clonedeep');
 
@Component({
  selector: 'work-item',
  templateUrl: './work-item.component.html',
  styleUrls: ['./work-item.component.scss'],
  providers: [],
})

export class WorkItemComponent implements OnInit, OnDestroy {
    taskTypes: any;
    taskStatuses: any;
    users: any;
    clients: any[];
    projects = [];
    allSprints: object[] = [];
    entry: object = null;

    allLabels: any[] = [];
    allMilestones: any[] = [];
    selectedTypes: any = [];
    selectedProjects: any = [];
    selectedUsers: any = [];
    selectedLabels: any = [];
    selectedMilestones: any = [];
    selectedReporterUsers: any[];
    selectedSprints: any[];

    usersByProject: object[] = undefined;

    original = [];
    labels: any[];
	milestones: any[];
    filteredLabels: Observable<any[]>;
	filteredMilestones: Observable<any[]>;
    separatorKeysCodes: number[] = [ENTER, COMMA];
	milestoneSeperatorKeysCodes: number[] = [ENTER, COMMA];

    disableComment: boolean = false;
    comments: any = [];
	commentTextarea = "";
    currentUserId;
	currentUserFullName = 'User';
    valid: boolean = true;
    error: String;

    isReadyToComment: boolean;
    fullyLoaded: boolean = false;
    projectsLoaded: boolean = false;
    updatingEntryLoaded: boolean = true;
    userHasActiveProjects: boolean = false;
    entryLoaded: boolean = false;
    projectsUsersLoaded: boolean = false;
    userAllowedToViewTask: boolean = false;
    isInitialLoad: boolean = false;

    unassignedUserValue: string = "unassignedId";
    unassignedValue = null;

    priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
	    {name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]

    id: FormControl = new FormControl();
    name: FormControl = new FormControl();
    reporter: FormControl = new FormControl();
    taskType: FormControl = new FormControl();
    taskStatus: FormControl = new FormControl();
    assignedUser: FormControl = new FormControl();
    plannedStartDate: FormControl = new FormControl();
    actualStartDate: FormControl = new FormControl();
    plannedEndDate: FormControl = new FormControl();
    actualEndDate: FormControl = new FormControl();
    commitDate: FormControl = new FormControl();
    description: FormControl = new FormControl();
    projectId: FormControl = new FormControl();
    priority: FormControl = new FormControl();
    sprint: FormControl = new FormControl();
    labelCtrl: FormControl  = new FormControl();
	milestoneCtrl: FormControl  = new FormControl();
    @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
	@ViewChild('milestoneInput') milestoneInput: ElementRef<HTMLInputElement>;

    canSeeTasks: boolean = false;
    canDeleteTasks: boolean = false;
    canChangeProject: boolean = false;

    constructor(private _settingsService: SetttingsService, private _projectsService: ProjectsService, private _auth0ManagementApiService: Auth0ManagementApiService,
    public dialog: MatDialog, public snackBar: MatSnackBar, private shareProject: ShareProject, public router: Router, private _sprintService: SprintService,
    private authService: AuthService) 
    { 
        this.authService.getUserPermissions().subscribe(userPermissions => {
            this.canSeeTasks = userPermissions.includes('read:projects');
            this.canDeleteTasks = userPermissions.includes('manage:projects');
            this.canChangeProject = userPermissions.includes('administer-tenant:administration');
        });
    }

	ngOnDestroy(): void {
		this.shareProject.reset();
	}

	ngOnInit() {
        this.isInitialLoad = true;
        this.shareProject.reset();
		this.fullyLoaded = false;
        this.projectsLoaded = false;
        var idParam = new URL(window.location.href).searchParams.get("id");
        if(idParam != null && this.canSeeTasks) {
            this.getTaskById(idParam);

            this.getTaskTypesAndStatuses();
            this.getClients();
            this.getLabels();
            this.getUniqueLabels();
            this.getMilestones();
            this.getUniqueMilestones();
            this.getUserIdAndFullName();
            this.getProjectsWithUsers();
            this.getSprints();
        }
        else {
            this.router.navigateByUrl('kanban-board');
        }
	}

    getTaskById(entryId){
        this.getComments(entryId);
        this._projectsService.getProjectTaskById(entryId).subscribe(data => {
            if(data == null) this.router.navigateByUrl('kanban-board');
            else this.loadDialog(data);
        },
        err => {
            this.snackBar.open("Error Retrieving Task", "", { duration: 2000 });
        });
    }

    loadDialog(entry) {
        this.entry = entry;
		this.id.setValue(entry.id);
        this.name.setValue(entry.name);
        this.taskType.setValue(entry.typeId);
        this.taskStatus.setValue(entry.taskStatus);
        this.assignedUser.setValue(entry.assignedUser);
        this.plannedStartDate.setValue(entry.plannedStartDate);
        this.actualStartDate.setValue(entry.actualStartDate);
        this.plannedEndDate.setValue(entry.plannedEndDate);
        this.actualEndDate.setValue(entry.actualEndDate);
        this.commitDate.setValue(entry.commitDate);
        this.reporter.setValue(entry.reporter);
        this.description.setValue(entry.description);
        this.projectId.setValue(entry.projectId);
        this.priority.setValue(entry.priority);
        this.sprint.setValue(entry.sprintId);
        
        this.error = '';
        this.labels = entry.labels;
		this.milestones = entry.milestones;

        this.entryLoaded = true;
        if(this.projectsUsersLoaded) this.filterUsersByProject(entry.projectId);

        this.filteredLabels = this.labelCtrl.valueChanges.pipe(
            startWith(null),
            map((label: string | null) => label ? this._filter(label) : this._filterCurrent()));

		this.filteredMilestones = this.milestoneCtrl.valueChanges.pipe(
			startWith(null),
			map((milestone: string | null) => milestone ? this._filterMilestone(milestone) : this._filterCurrentMilestone()));

        this.original = cloneDeep(entry);
    }

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    getSprints() {
		let result = []
		this._sprintService.getSprints().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.allSprints = result;

            var sprintList = this.allSprints.filter(x => x['projectId'] == this.projectId.value);
            this.selectedSprints = sprintList;
		});
	}

    getProjectsWithUsers() {
		var sharedProjectUserMappings = this.shareProject.getProjectUserMappings();
        if(sharedProjectUserMappings == null || sharedProjectUserMappings == undefined){
			this._settingsService.getProjectsWithUsers().subscribe(data => {
				this.usersByProject = Object.assign([], data);
                
                this.projectsUsersLoaded = true;
                if(this.entryLoaded) this.filterUsersByProject(this.projectId.value);
			});
		}else {
            this.usersByProject = sharedProjectUserMappings;
            
            this.projectsUsersLoaded = true;
            if(this.entryLoaded) this.filterUsersByProject(this.projectId.value);
        }
    }

    filterUsersByProject(projectId, filterValue = "") {
        var sprintList = this.allSprints.filter(x => x['projectId'] == projectId);
        this.selectedSprints = sprintList;
        
        var projectUserList = this.usersByProject.filter(x => x['projectId'] == projectId);
        if (projectUserList != null && projectUserList.length > 0) {
            //Allows the edit/delete of the ticket
            if((projectUserList[0]['userIds'] != null && projectUserList[0]['userIds'].includes(this.currentUserId)) || this.canChangeProject){
                this.userAllowedToViewTask = true;
            }
            else {
                //If your user is not assigned to the project the task is about, it will remove you from the task
                this.router.navigateByUrl('kanban-board');
            }

            this.selectedUsers = cloneDeep(this.users.filter(function (user) {
                return projectUserList[0]['userIds'].includes(user.userId)
            }));
            if(filterValue != "")
                this.selectedUsers = this.selectedUsers.filter(user => user['fullName'].toLowerCase().includes(filterValue.toLowerCase()));
            this.selectedUsers = this.selectedUsers.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
        }
        else {
            //Project contains no users, most likely an inactive project
            if(this.isInitialLoad){
                this.router.navigateByUrl('kanban-board');
            }
            this.selectedUsers = [];
        }
        this.isInitialLoad = false; 
    }

    getClients() {
        var sharedClients = this.shareProject.getClients();
        if(sharedClients == undefined){
            this.clients = [];
            this.selectedProjects = [];
            let result = [];
            this._settingsService.getClients().subscribe(data => {
                result = Object.keys(data).map(key => [Number(key), data[key]]);
                this.clients = [];

                async.forEach(result,(client,inner_callback)=>{
                    this.clients.push(client['1']);
                    this.selectedProjects.push(client['1']);
                    inner_callback(null);
                },() => this.getProjects());
            });
        } else this.clients = sharedClients;
    }

    getMilestones() {
	    let result = [];
	    this._projectsService.getAllMilestones().subscribe(data => {
		    result = Object.keys(data).map(function (key) {
			    return data[key];
		    });
		  this.allMilestones = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
	    })
    }

    getUniqueMilestones() {
        let uniqueResult = [];
        this._projectsService.getAllTaskMilestones('true').subscribe(data => {
            uniqueResult = Object.keys(data).map(function (key) {
                return data[key];
            });
            this.selectedMilestones = uniqueResult.sort((a, b) => (a.name > b.name) ? 1 : -1);
        });
    }
  
    getLabels() {
        let result = [];
        this._projectsService.getAllLabels().subscribe(data => {
            result = Object.keys(data).map(function (key) {
                return data[key];
            });
            this.allLabels = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
        });
    }

    getUniqueLabels() {
        let uniqueResult = [];
        this._projectsService.getAllTaskLabels('true').subscribe(data => {
            uniqueResult = Object.keys(data).map(function (key) {
                return data[key];
            });
            this.selectedLabels = uniqueResult.sort((a, b) => (a.name > b.name) ? 1 : -1);;
        });
    }

    getProjects() {
        this._settingsService.getProjectsByClientId(this.clients, true, true).subscribe(data => {
            async.forEach(this.clients,(client,inner_callback)=>{
			    if (data[client['id']].length > 0) {
				    client['projects'] = data[client['id']];
				    if (client['projects'].length > 0) this.userHasActiveProjects = true;
			    }
			    inner_callback(null);
		    },() => {
                this.shareProject.setClients(this.clients);
                this.projectsLoaded = true;
            });
            for(let i in data) {
				for(let y = 0; y < data[i].length; y++){
					this.projects.push(data[i][y])
				}
			}
        });
    }

    getTaskTypesAndStatuses(){
        var count = 0;
        this._settingsService.getStatuses().subscribe(data => {
		    this.taskStatuses = data;
		    this.taskStatuses = this.taskStatuses.sort(function(a, b) {
			    return a.ordinal - b.ordinal;
			});
            count = count + 1;
            if(count == 3) {
                this.fullyLoaded = true;
            }
        });
        this._settingsService.getTaskTypes().subscribe(data => {
            this.taskTypes = data;
		    this.selectedTypes = data;
            count = count + 1;
            if(count == 3) {
                this.fullyLoaded = true;
            }
        });
        this._settingsService.getUsers(true).subscribe(data => {
		    this.users = data;
		    this.selectedUsers = data;
            //adding an unassigned user to filter not assigned tasks
		    this.selectedUsers.push({userId: "unassignedId", fullName: "Unassigned"})
		    this.selectedReporterUsers = this.selectedUsers.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
            count = count + 1;
            if(count == 3) {
                this.fullyLoaded = true;
            }
        });
    }

    getComments(taskId){
		this._projectsService.getTaskCommentsByTaskId(taskId).subscribe(
			data =>{
				this.comments = [];
				if(data == null || data == undefined){
					//no comments
				}else{
					this.putNewCommentsIntoCommentSection(data);
					this.commentTextarea = '';
				}
			},
			err =>{
				this.snackBar.open("Error Getting Work Item", "", { duration: 2000 });
			}
		)
	}

    getUserIdAndFullName(){
		this.isReadyToComment = false;
		this.authService.getUserId().subscribe(userId=>{
			this.currentUserId = userId
			this._auth0ManagementApiService.getUserDetails(this.currentUserId).subscribe(details => {
				this.currentUserFullName = details.fullName
				this.isReadyToComment = true;
			});
		});
	}

	addComment(commentContent:string){
		if (commentContent.length <= 500){
            this.disableComment = true;
			let addCommentObj = {taskId: this.id.value, comment: this.escapeSingleQuote(commentContent), userId: this.currentUserId, fullName: this.currentUserFullName};
			this._projectsService.putCommentToTask(addCommentObj).subscribe(
				data=>{
                    this.disableComment = false;
                    if (data != null && data[0] != null){
                        this.commentTextarea = '';
						this.getComments(this.id.value);
					}
                    else {
                        this.snackBar.open("Error Posting Comment", "", { duration: 2000 });
                    }
				},
				err=>{
                    this.disableComment = false;
					this.snackBar.open("Error Posting Comment", "", { duration: 2000 });
				}
			);
		}
	}

	putNewCommentsIntoCommentSection(commentsData){
		for (let i=0;i<Object.keys(commentsData).length;i++){
			commentsData[i].timestamp = new Date(commentsData[i].timestamp);
			this.comments.push(commentsData[i]);
		}
	}

	// escapes single quote substring of any length
	// used to properly query
	escapeSingleQuote(string){
		while (string.includes("''")){
			string = string.replace("''","'")
		}
		return string.replace("'","''")
	}
  
    selected(event: MatAutocompleteSelectedEvent): void {
        this.labels.push(event.option.value);
        this.labelInput.nativeElement.value = '';
        this.labelCtrl.setValue(null);
    }

	selectedMilestone(event: MatAutocompleteSelectedEvent): void {
        this.milestones.push(event.option.value);
        this.milestoneInput.nativeElement.value = '';
        this.milestoneCtrl.setValue(null);
    }

    onKeyUsers(value) {
        if (this.projectId.value != null) {
            this.filterUsersByProject(this.projectId.value, value);
        }
    }

    onKeyReporterUsers(value) {
        this.selectedReporterUsers = this.filterReporterUsers(value);
    }

    filterReporterUsers(value: string) {
        return this.users.filter(user => user['fullName'].toLowerCase().includes(value.toLowerCase()) && user['userId'] != 'unassignedId');
    }

    onKeyProjects(value) {
        this.selectedProjects = this.filterProjects(value);
    }

    filterProjects(value: string) {
        let filter = value.toLowerCase();
        let filteredClientsAndProjects = this.clients.filter(option => option['name'].toLowerCase().includes(filter));
		for(var i = 0; i < this.clients.length; i++){
			if(filteredClientsAndProjects.find(option => option['id'] == this.clients[i]['id']) == undefined){
				if(this.clients[i]['projects'] != null){
					let tempClient = cloneDeep(this.clients[i]);
					tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
					if(tempClient['projects'].length > 0){
						filteredClientsAndProjects.push(tempClient);   
					}
				}
			}
		}
		return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });		
	}

    onKeyType(value) {
        this.selectedTypes = this.filterTypes(value);
    }

    filterTypes(value: string) {
        let filter = value.toLowerCase();
        return this.taskTypes.filter(option => option['typeName'].toLowerCase().includes(filter));
    }

    checkAndUpdate() {
        if (this.isValid()) {
            this.addUpdateTask();
        }
    }

    isValid() {
        if (this.name.value == null || this.name.value == '') {
            this.error = 'Work Item Name is required';
            return false;
        }
        if (this.taskStatus.value == null) {
            this.error = 'Work Item Status is required';
            return false;
        }
        if(this.projectId.value == null){
            this.error = 'Project is required';
            return false;
        }
        if (this.priority.value == null) {
            this.error = 'Priority is required';
            return false;
        }

        if (this.plannedStartDate.value != null && this.plannedEndDate.value != null && new Date(this.plannedStartDate.value) > new Date(this.plannedEndDate.value)) {
            this.error = 'Planned Start Date must be before Planned End Date';
            return false;
        }
        if (this.actualStartDate.value != null && this.actualEndDate.value != null && this.actualStartDate.value > this.actualEndDate.value) {
            this.error = 'Actual Start Date must be before Actual End Date';
            return false;
        }
        else {
            this.error = '';
            this.valid = true;
            return true;
        }
    }

    addUpdateTask(entry = null) {
        let updates = [];
        if (entry == null) {
            //An Update for a task
            entry = {
                id: this.entry["id"],
                projectId: this.projectId.value,
                priority: this.priority.value,
                name: this.name.value,
                typeId: this.taskType.value,
                reporter: this.reporter.value,
                taskStatus: this.taskStatus.value,
                assignedUser: this.assignedUser.value,
                plannedStartDate: this.plannedStartDate.value,
                actualStartDate: this.actualStartDate.value,
                plannedEndDate: this.plannedEndDate.value,
                actualEndDate: this.actualEndDate.value,
                commitDate: this.commitDate.value,
                description: this.description.value,
                sprintId: this.sprint.value,
                labels: this.labels,
				milestones: this.milestones,
				modifiedDate: new Date()
            }
        } 

        updates = this.getChanges(entry, this.original);
        entry['changes'] = updates;

        this.updatingEntryLoaded = false;
        this._projectsService.putProjectTask(entry).subscribe(
            data => {
                this.original = entry;
                this.updatingEntryLoaded = true;
                this.snackBar.open("Work Item Updated Successfully", "", { duration: 2000 });
				this.getMilestones();
                this.getLabels();
            },
            err => {
                this.snackBar.open("Error Updating Work Item", "", { duration: 2000 });
            }
        );
    }

    getChanges(result, origin){
        let changes = [];
        var originalTask = this.taskStatuses.find(t => t.statusId == origin['taskStatus']);
        var originalType = this.taskTypes.find(t => t.typeId == origin['typeId']);
        var originalPriority = this.priorities.find(p => p.id == origin['priority']);
        var project = this.projects.find(p => p['id'] == this.projectId.value);
        var updatedTask = this.taskStatuses.find(t => t.statusId == result['taskStatus']);
        var updatedType = this.taskTypes.find(t => t.typeId == result['typeId']);
        var updatedPriority = this.priorities.find(p => p.id == result['priority']);

        if(origin['plannedStartDate'] == null) var plannedStartDate = 'Empty'; else plannedStartDate = new Date(origin['plannedStartDate']).toDateString();
        if(origin['plannedEndDate'] == null) var plannedEndDate  = 'Empty'; else plannedEndDate = new Date(origin['plannedEndDate']).toDateString();
        if(origin['actualStartDate'] == null) var actualStartDate = 'Empty'; else actualStartDate = new Date(origin['actualStartDate']).toDateString();
        if(origin['actualEndDate'] == null) var actualEndDate = 'Empty'; else actualEndDate = new Date(origin['actualEndDate']).toDateString();
        if(origin['commitDate'] == null) var commitDate = 'Empty'; else commitDate = new Date(origin['commitDate']).toDateString();
        if(result['plannedStartDate'] == null) var updatedPlannedStartDate = 'Empty'; else updatedPlannedStartDate = new Date(result['plannedStartDate']).toDateString();
        if(result['plannedEndDate'] == null) var updatedPlannedEndDate = 'Empty'; else updatedPlannedEndDate = new Date(result['plannedEndDate']).toDateString();
        if(result['actualStartDate'] == null) var updatedActualStartDate = 'Empty'; else updatedActualStartDate = new Date(result['actualStartDate']).toDateString();
        if(result['actualEndDate'] == null) var updatedActualEndDate = 'Empty'; else updatedActualEndDate = new Date(result['actualEndDate']).toDateString();
        if(result['commitDate'] == null) var updatedCommitDate = 'Empty'; else updatedCommitDate = new Date(result['commitDate']).toDateString();
        if(origin['name'] != result['name']) changes.push("<strong>Original name: </strong>"  + origin['name'] + '   <strong>Updated name: </strong>' + result['name']);
        if(origin['description'] != result['description']) changes.push('<strong>Updated description: </strong>' + result['description']);
        if(origin['taskStatus'] != result['taskStatus']) changes.push("<strong>Original task status: </strong>" + originalTask.statusName + '   <strong>Updated task status: </strong>' + updatedTask.statusName);   
        if(origin['priority'] != result['priority']) changes.push("<strong>Original priority: </strong>" + originalPriority.name + '   <strong>Updated priority: </strong>' + updatedPriority.name);
        if(origin['typeId'] != result['typeId']){ if(originalType == null){changes.push("<strong>Original type: </strong>" + "No type" + '   <strong>Updated type: </strong>' + updatedType.typeName);} else{changes.push("<strong>Original type: </strong>" + originalType.typeName + '   <strong>Updated type: </strong>' + updatedType.typeName);}}
        if(origin['plannedStartDate'] != result['plannedStartDate']) changes.push("<strong>Original planned start date: </strong>" + plannedStartDate + '   <strong>Updated planned start date: </strong>' + updatedPlannedStartDate);
        if(origin['plannedEndDate'] != result['plannedEndDate']) changes.push("<strong>Original planned end date: </strong>" + plannedEndDate + '   <strong>Updated planned end date: </strong>' + updatedPlannedEndDate);
        if(origin['actualStartDate'] != result['actualStartDate']) changes.push("<strong>Original actual start date: </strong>" + actualStartDate + '   <strong>Updated actual start date: </strong>' + updatedActualStartDate);
        if(origin['actualEndDate'] != result['actualEndDate']) changes.push("<strong>Original actual end date: </strong>" + actualEndDate + '   <strong>Updated actual end date: </strong>' + updatedActualEndDate);
        if(origin['commitDate'] != result['commitDate']) changes.push("<strong>Original commit date: </strong>" + commitDate + '   <strong>Updated commit date: </strong>' + updatedCommitDate);
        if(!(this.arraysEqual(origin['labels'], result['labels']))) { origin['labels'].forEach((element, index) => { changes.push("Original Label "+[index+1] + ": " +  element.name )}); if(result['labels'].length == 0) {changes.push("Labels Removed");}  else {result['labels'].forEach((element, index) => {changes.push("Updated Label "+[index+1] + ": " +  element.name ) });}}
        if(!(this.arraysEqual(origin['milestones'], result['milestones']))) { origin['milestones'].forEach((element, index) => { changes.push("Original Milestone "+[index+1] + ": " +  element.name ) }); if(result['milestones'].length == 0) {changes.push("Milestones Removed");}  else {result['milestones'].forEach((element, index) => {changes.push("Updated Milestone "+[index+1] + ": " +  element.name ) });}}
        if(origin['assignedUser'] != result['assignedUser']) changes.push("Assigned User has been changed");
        if(origin['reporter'] != result['reporter']) changes.push("Reported User has been changed");
        if(origin['sprintId'] != result['sprintId']) changes.push("Sprint has been changed");
        
        result['projectName'] = "project : " + project['name'];
        return changes;
    }

    arraysEqual(a, b) {
        if (a == b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;
      
        for (var i = 0; i < a.length; ++i) {
          if (a[i].id != b[i].id){
              return false;
          }
        }
        return true;
    }

    private _filter(value): any[] {
        if(typeof value == 'string'){
            const filterValue = value.toLowerCase();
            let tempLabels = JSON.parse(JSON.stringify(this.allLabels));
            if(this.labels != undefined)
                for(var i = 0; i < this.labels.length; i++){
                    tempLabels = tempLabels.filter(tempLabel => tempLabel.id != this.labels[i].id)
                }
            return tempLabels.filter(label => label.name.toLowerCase().indexOf(filterValue) != -1);
        }
        else {
            return this._filterCurrent();
        }
    }

	private _filterMilestone(value): any[] {
        if(typeof value == 'string'){
            const filterValue = value.toLowerCase();
            let tempMilestones = JSON.parse(JSON.stringify(this.allMilestones));
            if(this.milestones != undefined)
                for(var i = 0; i < this.milestones.length; i++){
                    tempMilestones = tempMilestones.filter(tempMilestone => tempMilestone.id != this.milestones[i].id)
                }
            return tempMilestones.filter(milestone => milestone.name.toLowerCase().indexOf(filterValue) != -1);
        }
        else {
            return this._filterCurrentMilestone();
        }
    }

    private _filterCurrent(): any[] {
        let tempLabels = JSON.parse(JSON.stringify(this.allLabels));
        if(this.labels != undefined){
            for(var i = 0; i < this.labels.length; i++){
                tempLabels = tempLabels.filter(tempLabel => tempLabel.id != this.labels[i].id)
            }
        }
        return tempLabels.slice()
    }

	private _filterCurrentMilestone(): any[] {
        let tempMilestones = JSON.parse(JSON.stringify(this.allMilestones));
        if(this.milestones != undefined){
            for(var i = 0; i < this.milestones.length; i++){
                tempMilestones = tempMilestones.filter(tempMilestone => tempMilestone.id != this.milestones[i].id)
            }
        }
        return tempMilestones.slice()
    }

    onKeySprints(value) {
		this.selectedSprints = this.filterSprints(value);
	}

    filterSprints(value: string) {
		let filter = value.toLowerCase();
        var sprintList = this.allSprints.filter(x => x['projectId'] == this.projectId.value);
		return sprintList.filter(option => option['name'].toLowerCase().includes(filter));
	}

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
          this.labels.push({id: -1, name: value.trim()});
        }
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.labelCtrl.setValue(null);
    }

	addMilestone(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
 
		if ((value || '').trim()) {
			this.milestones.push({id: -1, name: value.trim()});
		  }
		  // Reset the input value
		  if (input) {
			input.value = '';
		  }
		  this.milestoneCtrl.setValue(null);
	}

    remove(label: any): void {
        if(label.id == -1){
            const index = this.labels.indexOf(label);

            if (index >= 0) {
              this.labels.splice(index, 1);
            }
        }
        else {
            this.labels = this.labels.filter(curr => curr.id != label.id)
            this.labelCtrl.setValue(null);
        }
    }

	removeMilestone(milestone: any): void {
		if(milestone.id == -1){
            const index = this.milestones.indexOf(milestone);

            if (index >= 0) {
              this.milestones.splice(index, 1);
            }
        }
        else {
            this.milestones = this.milestones.filter(curr => curr.id != milestone.id)
            this.milestoneCtrl.setValue(null);
        }
	}

    clearLabels(){
        this.labels = [];
        this.labelCtrl.setValue(null);
    }

	clearMilestones() {
		this.milestones = [];
        this.milestoneCtrl.setValue(null);
	}

    deleteTask(item) {
        if(this.canDeleteTasks){
            const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                width: '585px',
                height: 'auto'
            });
            dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
                if (result !== undefined) {
                    if (result['toDelete']) {
                        this.snackBar.open('Deleting Work Item', '', { duration: 2000 });
                        this.updatingEntryLoaded = false;
                        this.isReadyToComment = false;
                        this._projectsService.deleteTaskById(item.id).subscribe(
                            data => {
                                this.snackBar.open("Work Item Deleted", "", { duration: 2000 });
                                this.router.navigateByUrl('kanban-board');
                            },
                            err => {
                                this.snackBar.open("Error Deleting Work Item", "", { duration: 2000 });
                            }
                        );
                    }
                }
            });
        }
    }
}
